import { Typography } from '@creditas/typography'
import { styled, css, mq } from '@creditas/stylitas'
import { fillMediaBase } from 'ui/utils/fillMediaBase'
import { Container as CreditasContainer } from '@creditas/layout'
import Img from 'gatsby-image'
import { getColorToken } from 'src/utils/getColorToken'

const ImageElement = styled(Img)``

const Wrapper = styled.div(
  () => css`
    background-color: ${getColorToken('neutral.15')};
  `,
)

const Container = styled(CreditasContainer)`
  display: flex;
  padding: 16px;

  ${mq({
    alignItems: fillMediaBase('flex-start', 'center'),
    justifyContent: fillMediaBase('space-evenly', 'space-between'),
    flexDirection: fillMediaBase('column-reverse', 'row'),
  })}
`

const Text = styled(Typography)(
  () => css`
    letter-spacing: 0.3px;
    font-weight: 400;
    color: ${getColorToken('neutral.90')};

    ${mq({
      fontSize: fillMediaBase('12px', '14px'),
    })}
  `,
)

export { ImageElement, Wrapper, Container, Text }
