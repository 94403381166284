import { styled, css } from '@creditas/stylitas'
import { media } from '@creditas-ui/utilities'
import { Container as UiContainer } from '@creditas/layout'
import { ButtonCta } from 'components/ButtonCta'
import { getColorToken } from 'src/utils/getColorToken'

export const Container = styled.header`
  width: 100%;
  background-color: ${getColorToken('neutral.0')};
  position: fixed;
  z-index: 100;

  nav {
    .logo {
      margin-right: 36px;
      display: flex;
      align-items: center;
    }

    .menu {
      display: none;
    }

    .contentWrapper {
      padding-top: 0px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;

      ${media.up('5xl')} {
        justify-content: flex-start;
      }

      ul {
        display: flex;
        align-items: flex-start;
        list-style: none;

        li {
          color: ${getColorToken('neutral.90')};

          a svg {
            display: none;
          }
        }
      }

      .menu-item-desktop a {
        display: block;
        padding: 28px 16px;
        font-weight: 600;

        ${media.up('5xl')} {
          padding: 28px 32px;
        }

        &:link,
        &:visited,
        &:hover,
        &:active {
          text-decoration: none;
        }

        &.menu-item-active,
        &:hover {
          position: relative;

          &::after {
            content: '';
            position: absolute;

            margin: auto;
            left: 0;
            right: 0;
            height: 4px;
            background: ${getColorToken('primary.60')};
            top: 0;
          }
        }

        color: inherit;
      }
    }
  }

  @media (max-width: 767px) {
    position: relative;
    z-index: 3;

    nav {
      background-color: ${getColorToken('neutral.0')};

      .menu {
        display: block;
      }

      .contentWrapper {
        position: fixed;
        height: 56px;
        width: 100%;
        background: ${getColorToken('neutral.0')};

        a.logo svg {
          width: 122px;
          height: 36px;
        }

        ul {
          display: none;
        }

        .menu {
          cursor: pointer;
        }
      }

      a.logo,
      input + svg {
        width: 122px;
        height: 36px;
      }

      .contentWrapper {
        border-bottom: 1px solid ${getColorToken('neutral.10')};
        padding: 20px 16px 16px;
      }
    }
  }

  .suspended_menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    background: ${getColorToken('neutral.0')};
    top: 56px;
    z-index: 1;
    position: fixed;
    height: 100%;
    padding: 32px 20px 68px;

    width: 100%;

    display: flex;
    align-items: flex-start;

    ul {
      width: 100%;
      list-style: none;

      li {
        width: 100%;
        color: ${getColorToken('neutral.100')};

        a {
          font-size: 26px;
          line-height: 36px;
          display: flex;
          justify-content: space-between;
        }

        &:not(:first-of-type) {
          margin-top: 32px;
        }
      }

      a {
        &:link,
        &:visited,
        &:hover,
        &:active {
          text-decoration: none;
        }

        color: inherit;
      }
    }
  }
`

export const CreditasContainer = styled(UiContainer)`
  padding: 0;
`

export const DownloadAppContainer = styled.div`
  margin-top: 112px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: ${getColorToken('neutral.60')};
  }

  > div {
    margin-top: 18px;

    display: flex;

    .app {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: ${getColorToken('neutral.10')};

      display: flex;
      justify-content: center;
      align-items: center;

      cursor: pointer;

      & + .app {
        margin-left: 18px;
      }
    }
  }

  @media (max-width: 767px) {
    margin-top: 64px;
    margin-bottom: 27px;
  }
`

export const ScrollButton = styled(ButtonCta)(
  ({ theme }) => css`
    background: ${theme.colorBackgroundLight};
    outline: none;
    font-size: 16px;
    line-height: 24px;
    color: ${theme.colorNeutral90};
    height: 48px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    min-width: 0;
    margin-left: auto;
    margin-right: 8px;
    padding-left: 20px;
    padding-right: 20px;

    span {
      font-weight: bold;
    }

    svg {
      fill: ${theme.colorNeutral90};
    }

    &:hover {
      background-color: ${theme.colorBackgroundDefault};
    }

    @media (max-width: 768px) {
      display: none;
    }
  `,
)

export const ScrollLink = styled.span(
  ({ theme }) => css`
    display: block;
    width: 100%;
    color: ${theme.colorBrandPrimaryDefault};
    font-size: 26px;
    line-height: 36px;
  `,
)
