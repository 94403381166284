import { styled, mq, css } from '@creditas/stylitas'
import { Typography } from '@creditas/typography'
import { media } from '@creditas-ui/utilities'
import { Link } from 'gatsby'
import { fillMediaBase } from 'ui/utils/fillMediaBase'
import { getColorToken } from 'src/utils/getColorToken'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  width: -webkit-fill-available;

  ${media.up('5xl')} {
    width: auto;
    flex-direction: row;
    align-items: center;
  }
`

const ItemLink = styled(Link)`
  text-decoration: none;
  margin: 16px;
  flex: 2 0 auto;
  color: ${getColorToken('neutral.90')};

  ${media.up('5xl')} {
    margin: 16px 24px;
  }

  &:hover {
    text-decoration: underline;
  }
`

const Title = styled(Typography)(
  () => css`
    letter-spacing: 0.3px;
    font-weight: 700;
    color: ${getColorToken('neutral.90')};

    ${mq({
      fontSize: fillMediaBase('16px', '14px'),
      lineHeight: fillMediaBase('24px', '20px'),
    })}
  `,
)

export { Wrapper, ItemLink, Title }
